@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}
/* START: General styles */
.max-width {
    @apply max-w-[1440px] mx-auto;
  }
  
  .padding-x {
    @apply sm:px-16 px-6;
  }
  
  .padding-y {
    @apply py-4;
  }
  .customWidth{
    @apply lg:max-w-[777px] !important;
  }
  
  .flex-center {
    @apply flex items-center justify-center;
  }
  
  .flex-between {
    @apply flex justify-between items-start lg:items-center;
  }
  
  .custom-btn {
    @apply flex flex-row relative justify-center items-center py-3 px-6 outline-none;
  }
  
  /* END: General styles */
  .clone{
    color: #FFCC01;
    @apply ml-2
  }
  .clone.about{
    color: #370067;
  }
  
  .navLink{
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 30px;
    /* color: #ababab; */
  }
  
  .navLink:hover{
    color: #FFCC01;
  }
  .navLink.active::before{
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 3px;
    background-color: #FFCC01;
  }
  
  /* START: Hero styles */
  .hero {
    @apply flex lg:flex-row flex-col gap-5 relative z-0  mx-auto;
  }
  
  .hero__title {
    @apply  2xl:text-[72px] sm:text-[50px] text-[30px] font-extrabold;
  }
  
  .hero__subtitle {
    @apply text-[27px] text-black-100 font-light mt-5;
  }
  
  .hero__image-container {
    @apply xl:flex-[1] flex justify-end items-end;
  }
  
  .hero__image {
    @apply relative xl:w-full w-[90%] xl:h-full h-[590px] z-0;
  }
  .whiteText{
    @apply text-white lg:text-left text-center lg:max-w-none max-w-sm
  }
  .divider::after{
    content: "";
    height: 1px;
    width: 20px;
    border: 1px dashed #DADADD;
    position: absolute;
    right: 0;
    margin-right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  @media (max-width: 768px) {
    .navLink.active::before{
       display: none;
    }
    .clone{
        @apply ml-0
    }
  }
  